const redirectedPathnames = [
  '/c/customer-service',
  '/c/about',
  '/c/adaptive',
  '/c/associates-program',
  '/c/contact-us',
  '/c/content-usage-terms',
  '/c/customer-reviews',
  '/c/privacy-policy',
  '/c/shipping-and-delivery-questions',
  '/c/shipping-and-returns',
  '/c/terms-of-use',
  '/c/vip-faqs',
  '/c/vip-terms-and-conditions',
  '/c/zappos-for-good-faq',
  '/c/zappos-gift-cards',
  '/c/zappos-insights',
  '/c/zaw-amazon-faq',
  '/c/zaw-returns'
];

export const redirectLandingPageToKratos = ({
  currentPath,
  isLandingPageKratos,
  search
}: {
  currentPath: string;
  isLandingPageKratos: boolean;
  search?: URLSearchParams;
}) => {
  const isEligibleLandingPage = redirectedPathnames.includes(currentPath);
  if (isLandingPageKratos && isEligibleLandingPage) {
    // URLSearchParams has a size field but it's relatively recent so need a different way to determine if there are any parameters
    const queryString = search ? search.toString() : '';
    return {
      isRedirected: true,
      isEligibleLandingPage,
      redirectPath: queryString ? `/kratos${currentPath}?${queryString}` : `/kratos${currentPath}`
    };
  }

  return {
    isRedirected: false,
    isEligibleLandingPage
  };
};
