import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ExecutionEnvironment from 'exenv';

import { pvLanding } from 'events/landing';
import { firePixelServer } from 'actions/pixelServer';
import { pageTypeChange } from 'actions/common';
import { getHeartProps } from 'helpers/HeartUtils';
import { fetchTaxonomyBrandPage } from 'actions/landing/landingPageInfo';
import LandingSlot from 'containers/LandingSlot';
import { Loader } from 'components/Loader';
import SiteAwareMetadata from 'components/SiteAwareMetadata';
import { trackEvent, trackLegacyEvent } from 'helpers/analytics';
import { titaniteView, track } from 'apis/amethyst';
import { buildSeoBrandString } from 'helpers/SeoUrlBuilder';
import { stripSpecialCharsDashReplace } from 'helpers';
import { shouldLazyLoad } from 'helpers/LandingPageUtils';
import { stripAppRoot } from 'history/AppRootUtils';
import { fetchHeartList, getHearts, heartProduct, toggleHeartingLoginModal, unHeartProduct } from 'actions/hearts';
import marketplace from 'cfg/marketplace.json';
import { PAGE_TYPES } from 'constants/appConstants';

import css from 'styles/containers/taxonomyBrandPage.scss';

const { hasHearting } = marketplace;

export class TaxonomyBrandPage extends Component {
  static fetchDataOnServer(store, location, { brandId }) {
    return store.dispatch(fetchTaxonomyBrandPage(brandId));
  }

  componentDidMount() {
    const {
      isChildComponent,
      taxonomyBrandPage: { brandId: currentBrand, pageInfo },
      pageTypeChange,
      params: { brandId: newBrand },
      getHearts,
      fetchTaxonomyBrandPage
    } = this.props;

    pageTypeChange(PAGE_TYPES.BRAND_PAGE);

    // Get hearting list
    getHearts();

    if (!currentBrand || currentBrand !== newBrand) {
      fetchTaxonomyBrandPage(newBrand);
    } else if (pageInfo && pageInfo.brandName && currentBrand === newBrand) {
      this.useSeoUrl(pageInfo.brandName, currentBrand);
    }

    titaniteView();
    if (!isChildComponent && pageInfo) {
      this.trackPageView(newBrand, pageInfo.brandName);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      params: { brandId: nextBrand },
      location: { pathname: nextPathname },
      taxonomyBrandPage: { pageInfo: nextPageInfo }
    } = this.props;
    const {
      params: { brandId: currentBrand },
      fetchTaxonomyBrandPage,
      taxonomyBrandPage: { pageInfo }
    } = prevProps;
    const nextBrandName = nextPageInfo?.brandName;
    const brandName = pageInfo?.brandName;
    if (!currentBrand || currentBrand !== nextBrand) {
      fetchTaxonomyBrandPage(nextBrand);
    } else if (
      nextBrandName &&
      brandName &&
      currentBrand &&
      nextBrandName !== brandName &&
      stripAppRoot(nextPathname) !== buildSeoBrandString(brandName, currentBrand)
    ) {
      // Check if path changed and brand page has loaded. If client-routed with page having non SEO url, convert.
      this.useSeoUrl(nextBrandName, currentBrand);
    }

    if (nextBrandName && nextBrandName !== brandName) {
      this.trackPageView(nextBrand, nextBrandName);
    }
  }

  trackPageView = (brandId, brandName) => {
    const { firePixelServer, trackEvent } = this.props;
    trackEvent('TE_PV_BRANDPAGE', brandId);
    firePixelServer('brand', { brand: { id: brandId, name: brandName } }, 'genericBrandsTemplate');
    track(() => [
      pvLanding,
      {
        landingPageType: 'BRAND_PAGE',
        pageName: brandName
      }
    ]);
  };

  // Construct SEO url string, convert brand name to be SEO friendly, and call router to update url
  useSeoUrl = (name, id) => {
    const { search } = this.props.location;
    const seoUrlString = `${buildSeoBrandString(name, id)}${search ? search : ''}`;
    this.props.history.replacePreserveAppRoot(seoUrlString);
  };

  onTaxonomyComponentClick = e => {
    // Send Analytics component click data via trackLegacyEvent(). The format of this data will change in the future
    // but for now we're sending the landing page parameters the way analytics/siteops wants them, mostly
    // the same as how they're currently formatted in legacy
    e.stopPropagation();
    const {
      taxonomyBrandPage: {
        pageInfo: {
          brandName,
          slotData: {
            'primary-8': { componentName }
          }
        }
      },
      trackLegacyEvent
    } = this.props;
    const { currentTarget } = e;
    // Probably need to update action once other components are complete
    const action = `Brand-Landing-${brandName}-${componentName}`;
    const label = stripSpecialCharsDashReplace(currentTarget.getAttribute('data-eventlabel'));
    const value = stripSpecialCharsDashReplace(currentTarget.getAttribute('data-eventvalue'));
    trackLegacyEvent(action, label, value);
  };

  render() {
    const {
      taxonomyBrandPage: { isLoaded, pageInfo, slotOrder, brandId },
      isCustomer,
      heartProduct,
      toggleHeartingLoginModal,
      trackEvent,
      unHeartProduct,
      fetchHeartList
    } = this.props;
    if (!brandId || !isLoaded || !pageInfo) {
      return <Loader />;
    }
    const { slotData, brandName, pageLayout } = pageInfo;
    const { testId } = this.context;
    const thisContainer = this;
    const heartProps = {
      hasHearting,
      isCustomer,
      heartProduct,
      toggleHeartingLoginModal,
      trackEvent,
      unHeartProduct,
      fetchHeartList
    };
    const heartsData = getHeartProps(heartProps, {
      heartEventName: 'TE_BRAND_PAGE_PRODUCT_HEART',
      unHeartEventName: 'TE_BRAND_PAGE_PRODUCT_UNHEART'
    });

    // TODO: components/common/skipLinks
    return (
      <SiteAwareMetadata loading={!isLoaded}>
        <div
          className={css.pageWrap}
          data-test-id={testId('taxonomyBrandPage')}
          data-layout={pageLayout} // used for SiteMerch bookmarklets
          data-page-id={`${brandName}-Taxonomy`}
        >
          <h1 className="text-3xl font-bold" data-test-id={testId('brandName')}>
            {brandName}
          </h1>
          {/* {this.makeCrumbs()}
          <hr className="my-4 border-subtle" />*/}
          {slotOrder.map((slotName, slotIndex) => (
            <LandingSlot
              key={slotName}
              slotIndex={slotIndex}
              slotName={slotName}
              data={slotData[slotName]}
              onTaxonomyComponentClick={thisContainer.onTaxonomyComponentClick}
              slotHeartsData={slotData[slotName]?.componentName === 'genericBrandTrending' && heartsData}
              shouldLazyLoad={shouldLazyLoad(slotIndex)}
            />
          ))}
        </div>
      </SiteAwareMetadata>
    );
  }
}

TaxonomyBrandPage.defaultProps = {
  trackEvent,
  trackLegacyEvent
};

TaxonomyBrandPage.contextTypes = {
  testId: PropTypes.func
};

function mapStateToProps(state, { history, match: { params } }) {
  const isCustomer = !!(ExecutionEnvironment.canUseDOM && state.cookies['x-main']);
  return {
    isCustomer,
    taxonomyBrandPage: state.landingPage,
    history,
    params
  };
}

export default connect(mapStateToProps, {
  fetchTaxonomyBrandPage,
  firePixelServer,
  getHearts,
  heartProduct,
  unHeartProduct,
  fetchHeartList,
  toggleHeartingLoginModal,
  pageTypeChange
})(TaxonomyBrandPage);
