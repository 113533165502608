import { Component } from 'react';
import { connect } from 'react-redux';
import hoistNonReactStatics from 'hoist-non-react-statics';
import ExecutionEnvironment from 'exenv';
import sanitize from 'sanitize-html';
import { withRouter } from 'react-router-dom';
import { deepEqual } from 'fast-equals';

import { cn } from 'helpers/classnames';
import LandingSlot from 'containers/LandingSlot';
import LayoutTupac from 'containers/LayoutTupac';
import { evFitSurveyResponseFromUrl, pvHome, pvLanding } from 'events/landing';
import { pageTypeChange } from 'actions/common';
import { createViewHomePageMicrosoftUetEvent, pushMicrosoftUetEvent } from 'actions/microsoftUetTag';
import { firePixelServer } from 'actions/pixelServer';
import { fetchAllRecommenderDataIfNecessary, getIpRestrictedStatus, loadLandingPage, toggleEasyFlowModal } from 'actions/landing/landingPageInfo';
import { EASYFLOW_ENROLLMENT_URL } from 'constants/rewardsInfo';
import { Loader } from 'components/Loader';
import SiteAwareMetadata from 'components/SiteAwareMetadata';
import VipPrimeLink from 'components/landing/VipPrimeLink';
import { stripSpecialCharsDashReplace } from 'helpers';
import marketplace from 'cfg/marketplace.json';
import { titaniteView, track } from 'apis/amethyst';
import { trackEvent, trackLegacyEvent } from 'helpers/analytics';
import { setupLandingEventWatcher, shouldLazyLoad } from 'helpers/LandingPageUtils';
import { getHeartProps } from 'helpers/HeartUtils';
import { MartyContext } from 'utils/context';
import { getHearts, heartProduct, toggleHeartingLoginModal, unHeartProduct } from 'actions/hearts';
import { checkIsHomepage } from 'history/historyFactory.js';
import { onEvent } from 'helpers/EventHelpers';
import { isZAWPage } from 'helpers/zaw';
import { setFederatedLoginModalVisibility, setHFSearchTerm, updateOriginalTerm } from 'actions/headerfooter';
import { loadFromLocalStorage } from 'helpers/localStorageUtilities';
import { fireExplicitSearchEventFromLocalStorage } from 'helpers/searchInputUtils';
import { selectIsCustomer } from 'selectors/cookies';
import { getIsGoodsLabelPage } from 'utils/landing';
import { selectLandingPageInfo } from 'selectors/landing';
import { IS_PRINT_PROTOCOL } from 'common/regex';
import { PAGE_TYPES } from 'constants/appConstants';
import { ensureLoaded, SOREL_FORM } from 'helpers/contentSlotConfig';
import { useIsEnrolledInVariant } from 'hooks/unleash/useIsEnrolledInVariant';
import { FEATURE_LANDING_PAGE_KRATOS } from 'constants/features';
import { redirectLandingPageToKratos } from 'helpers/redirect';

import css from 'styles/containers/landing.scss';

export const LAYOUT_TUPAC = 'Tupac';

const {
  shortName,
  homepage,
  hasHearting,
  hasKratosLandingPages,
  features: { showAccountRewards }
} = marketplace;

const HEARTING_AWARE_COMPONENTS = ['genericBrandTrending', 'productSearch', 'recommender'];

export class Landing extends Component {
  static fetchDataOnServer(store, location, { pageName }) {
    return store.dispatch(loadLandingPage(checkIsHomepage(location.pathname) ? homepage : pageName, location));
  }

  /**
   *  Make sure that all the components are loaded before hydrating.
   *  This way, we will not have a flash of spinner from unloaded components
   *  on server side rendered pages.
   */
  static preHydrate(store) {
    const slotData = store.getState().landingPage?.pageInfo?.slotData;
    // if this is a landing page that doesnt exist, then the slot data will be empty
    const slotDataArray = slotData ? Object.values(slotData) : [];
    return Landing.loadSlotComponentsPromise(slotDataArray).promise;
  }

  /**
   * take a list of slots and return a promise that resolves when the components are loaded.
   *
   * Immediate returns "true" if the components are currently loaded. This allows
   * us to bypass "then",  which may not be executed immediately, even on a completed promise.
   *
   * @param slotDataArray
   * @return {{immediate: boolean, promise: Promise<void>}|{immediate: boolean, promise: Promise<Awaited<unknown>[]>}}
   */
  static loadSlotComponentsPromise(slotDataArray) {
    const loadPromises = [];
    for (const data of slotDataArray) {
      if (data) {
        const { promise } = ensureLoaded(data.componentName);
        if (promise) {
          loadPromises.push(promise);
        }
      }
    }
    if (!loadPromises.length) {
      // if no length, no components are loading,
      // ergo, all components are loaded.
      // (Promise.all does not resolve immediately)
      // at least on V8
      return { immediate: true, promise: Promise.resolve() };
    } else {
      // some components are not loaded.
      // wait for all of them to load before
      // setting componentLoaded true and
      // forcing a rerender.
      return { immediate: false, promise: Promise.all(loadPromises) };
    }
  }

  constructor(props) {
    super(props);
    this.onComponentClick = this.onComponentClick.bind(this);
    this.trackPageView = this.trackPageView.bind(this);
    this.makePageHeading = this.makePageHeading.bind(this);
    this.cancelDoScroll = 0;
    this.scrolled = false;
    this.componentsLoaded();
  }

  componentDidMount() {
    const {
      track,
      location = {},
      landingPage: { pageName: currentPage, pageInfo = {} },
      pageTypeChange,
      pushMicrosoftUetEvent,
      fetchAllRecommenderDataIfNecessary,
      params: { pageName: newPage },
      setupLandingEventWatcher,
      isHomepage,
      isLandingPageKratos,
      getHearts
    } = this.props;

    const { slotData } = pageInfo;

    const { search, pathname } = location;
    // Note: When we're on homepage, newPage is undefined
    const isDifferentPage = !currentPage || (newPage && currentPage !== newPage) || (isHomepage && currentPage !== homepage);

    // Set the correct page type
    const pageType = isHomepage ? PAGE_TYPES.HOME_PAGE : PAGE_TYPES.LANDING_PAGE;
    pageTypeChange(pageType);

    if (pageType === 'landing' && loadFromLocalStorage('explicitSearchEvent')) {
      fireExplicitSearchEventFromLocalStorage();
    }

    if (hasKratosLandingPages) {
      const { isRedirected, redirectPath } = redirectLandingPageToKratos({
        currentPath: pathname,
        isLandingPageKratos,
        search: new URLSearchParams(search)
      });
      if (isRedirected && redirectPath) {
        window.location.replace(redirectPath);
        return;
      }
    }

    if (isDifferentPage) {
      this.fetchData(isHomepage ? homepage : newPage);
    } else {
      // Track page view right away if we already have the data.
      // Otherwise wait for data first in willReceiveProps
      this.trackPageView(newPage);
    }

    if (isHomepage) {
      pushMicrosoftUetEvent(createViewHomePageMicrosoftUetEvent());
    }

    fetchAllRecommenderDataIfNecessary(slotData);

    // Get hearting list
    getHearts();

    const utmContent = new URLSearchParams(search).get('utm_content');

    if (utmContent && utmContent.includes('postPurchaseSizing')) {
      track(() => [evFitSurveyResponseFromUrl, { search }]);
    }

    setupLandingEventWatcher(this);

    this.getIpRestrictedInfo();
    this.scrollLogic();
  }

  componentDidUpdate(prevProps) {
    const {
      params: nextPropParams,
      location: { pathname, search },
      landingPage: nextLandingPage,
      isHomepage,
      isLandingPageKratos
    } = this.props;
    // if going from one landing page to another, update state
    const {
      params: { pageName: currentPage },
      landingPage: { pageInfo }
    } = prevProps;
    const { pageName: newPage } = nextPropParams;

    if (currentPage !== newPage) {
      const { isRedirected, redirectPath } = redirectLandingPageToKratos({
        currentPath: pathname,
        isLandingPageKratos,
        search: new URLSearchParams(search)
      });
      if (isRedirected && redirectPath) {
        window.location.replace(redirectPath);
        return;
      } else if (isHomepage) {
        this.fetchData(homepage);
      } else if (nextLandingPage.pageName !== newPage) {
        // only load this LP if it's not already loaded
        this.fetchData(newPage);
      }
      if (this.cancelDoScroll) {
        clearTimeout(this.cancelDoScroll);
        this.cancelDoScroll = 0;
      }
    }

    if (nextLandingPage.pageInfo && pageInfo?.canonicalUrl !== nextLandingPage?.pageInfo?.canonicalUrl) {
      this.trackPageView(newPage, this.props);
      this.getIpRestrictedInfo();
    }

    if (this.props.landingPage.isLoaded && !prevProps.landingPage.isLoaded) {
      // not to implementer landingPage.isLoaded is the ZCS data,  not that the page elements have rendered.
      this.scrolled = false;
    }

    this.scrollLogic();
  }

  componentWillUnmount() {
    if (this.cancelDoScroll) {
      clearTimeout(this.cancelDoScroll);
      this.cancelDoScroll = 0;
      this.scrolled = false;
    }
  }

  /**
   * internal subroutine to determine if all components to
   * render the info in ZCS's slotData are loaded.
   *
   * @return {boolean} - if components are loaded
   *                   - Note: if no slots data, this returns true.
   */
  componentsLoaded() {
    const slotData = this.props?.landingPage?.pageInfo?.slotData;
    const slotDataArray = slotData && Object.values(slotData);
    if (!slotDataArray) {
      return true;
    }
    const componentsNames = [];
    for (const data of slotDataArray) {
      componentsNames.push(data.componentName);
    }
    if (!deepEqual(componentsNames, this.componentsLoadedOrLoading)) {
      this.componentsLoadedOrLoading = componentsNames;
      const { immediate, promise } = Landing.loadSlotComponentsPromise(slotDataArray);
      if (!immediate) {
        this.componentLoaded = false;
        promise.then(() => {
          this.componentLoaded = true;
          if (typeof window !== 'undefined') {
            this.forceUpdate();
          }
        });
      } else {
        // then is not guaranteed to execute immediately if a promise is resolved.
        // so, if the return is immediate - set the components loaded.
        // no need to return false, force an update and do another react commit.
        this.componentLoaded = true;
      }
    }
    return !!this.componentLoaded;
  }

  doScroll(hash, retries = 3, timeout = 50) {
    const hashElement = document.getElementById(hash);
    if (hashElement) {
      hashElement.scrollIntoView();
      this.scrolled = true;
      this.cancelDoScroll = 0;
    } else if (retries > 0) {
      // if we can not find the element wait a little bit and try again.
      // note that this is a bit of a hack and that we might want to figure out if children
      // have finished loading instead.
      this.cancelDoScroll = setTimeout(() => this.doScroll(hash, retries - 1, timeout + 50), timeout);
    } else {
      // timed out
      this.cancelDoScroll = 0;
      this.scrolled = true;
    }
  }

  scrollLogic() {
    if (
      !this.scrolled && // have we scrolled?
      !this.cancelDoScroll // we are not in retries
    ) {
      const hash = this.props.location.hash.replace(/^#/, '');
      if (!hash) {
        this.scrolled = true;
      } else {
        this.doScroll(hash);
      }
    }
  }

  static contextType = MartyContext;

  getIpRestrictedInfo = () => {
    const {
      landingPage: { ipStatus: { callCompleted } = {}, pageInfo: { ipRestrictedContentPresent = false } = {} },
      getIpRestrictedStatus
    } = this.props;
    if (ipRestrictedContentPresent && !callCompleted && ExecutionEnvironment.canUseDOM) {
      getIpRestrictedStatus();
    }
  };

  fetchData(newPage) {
    const { loadLandingPage } = this.props;
    loadLandingPage(newPage, window.location);
  }

  trackPageView(pageName, props = this.props) {
    const {
      firePixelServer,
      trackEvent,
      track,
      isHomepage,
      landingPage: { pageInfo }
    } = props;

    if (isHomepage) {
      trackEvent('TE_PV_HOMEPAGE');
      firePixelServer('home');
    } else {
      trackEvent('TE_PV_LANDINGPAGE');
      firePixelServer('landing', {}, pageName);
    }

    titaniteView();

    const slotData = pageInfo ? pageInfo.slotData : null;
    if (isHomepage && slotData) {
      track(() => [pvHome, { slotData }]);
    } else if (slotData) {
      track(() => [pvLanding, { pageName, slotData }]);
    }
  }

  makePageHeading(isFullWidth) {
    const {
      landingPage: {
        pageInfo: { pageHeading }
      },
      isHomepage
    } = this.props;
    if (isHomepage) {
      return (
        <h1 className={'sr-only'} data-test-id={this.context.testId('heading')}>
          {`${shortName} Homepage`}
        </h1>
      );
    } else if (pageHeading) {
      return (
        <h1
          className={cn(css.heading, { [css.fullWidth]: isFullWidth })}
          data-test-id={this.context.testId('heading')}
          dangerouslySetInnerHTML={{ __html: sanitize(pageHeading) }}
        />
      );
    }
  }

  makeAction = () => {
    const { landingPage, isHomepage } = this.props;
    let action = `Landing-${landingPage.pageInfo.subPageType}-${landingPage.pageName}`;
    // special labels for homepage per analytics instructions
    if (isHomepage) {
      action = `Gateway-${landingPage.pageName}`;
    }
    return action;
  };

  onComponentClick(e) {
    // Send Analytics component click data via trackLegacyEvent(). The format of this data will change in the future
    // but for now we're sending the landing page parameters the way analytics/siteops wants them, mostly
    // the same as how they're currently formatted in legacy
    const { trackLegacyEvent, toggleEasyFlowModal, setHFSearchTerm, landingPage, isCustomer, setFederatedLoginModalVisibility } = this.props;
    const { currentTarget } = e;
    const action = this.makeAction();
    const label = stripSpecialCharsDashReplace(currentTarget.getAttribute('data-eventlabel'));
    const value = stripSpecialCharsDashReplace(currentTarget.getAttribute('data-eventvalue'));

    // if link contains EasyFlow path, display the modal instead
    if (currentTarget.pathname === EASYFLOW_ENROLLMENT_URL) {
      e.preventDefault();
      toggleEasyFlowModal(true);
    }

    if (!isCustomer && getIsGoodsLabelPage(landingPage.pageName) && IS_PRINT_PROTOCOL.test(currentTarget.href)) {
      e.preventDefault();

      const { pathname, search } = this.props.location;
      const returnTo = encodeURIComponent(`${pathname}${search}`);

      setFederatedLoginModalVisibility(true, { returnTo });
    }

    e.stopPropagation();

    trackLegacyEvent(action, label, value);

    setHFSearchTerm('');
  }

  makeLayout = (pageLayout, pageName, pageInfo, slotData, slotOrder, ipStatus, slotContentTypesList, heartsData, isFullWidth, isCustomer) => {
    if (pageLayout === LAYOUT_TUPAC) {
      return (
        <LayoutTupac
          pageName={pageName}
          slotData={slotData}
          slotOrder={slotOrder}
          onComponentClick={this.onComponentClick}
          ipStatus={ipStatus}
          slotContentTypesList={slotContentTypesList}
          heartingAwareComponents={HEARTING_AWARE_COMPONENTS}
          heartsData={heartsData}
          isFullWidth={isFullWidth}
        />
      );
    }

    // purposeful hard coding a form on `sorel-giveaway` page in slot 2 - https://jira.zappos.net/browse/DK-964
    const slotOrderModified = pageName === 'sorel-giveaway' ? [...slotOrder.slice(0, 1), SOREL_FORM, ...slotOrder.slice(1)] : slotOrder;
    const slotDataModified =
      pageName === 'sorel-giveaway'
        ? {
            ...slotData,
            ...{
              [SOREL_FORM]: {
                isCustomer: isCustomer,
                componentName: SOREL_FORM,
                surveyName: 'sorel-giveaway-data',
                formHeading: 'Zappos x SOREL NYC Sweepstakes Entry Form',
                submittedMsg:
                  'Thank you for entering the Zappos x SOREL NYC Sweepstakes. Winner will be notified via email. Read full terms and conditions to learn more.'
              }
            }
          }
        : slotData;

    return slotOrderModified.map((slotName, slotIndex) => (
      <LandingSlot
        key={slotName}
        slotName={slotName}
        slotIndex={slotIndex}
        data={slotDataModified[slotName]}
        pageName={pageName}
        pageInfo={pageInfo}
        onComponentClick={this.onComponentClick}
        slotHeartsData={HEARTING_AWARE_COMPONENTS.includes(slotData[slotName]?.componentName) && heartsData}
        shouldLazyLoad={shouldLazyLoad(slotIndex)}
        ipStatus={ipStatus}
        slotContentTypesList={slotContentTypesList}
        isFullWidth={isFullWidth}
      />
    ));
  };

  render() {
    const {
      landingPage: { isFaqPage, isLoaded, pageInfo, pageName, slotOrder, content },
      isCustomer,
      heartProduct,
      toggleHeartingLoginModal,
      trackEvent,
      unHeartProduct,
      ipStatus,
      slotContentTypesList
    } = this.props;

    const componentsLoaded = this.componentsLoaded();

    if (
      !isLoaded || // all data is loaded
      !componentsLoaded // all components are loaded.
    ) {
      return <Loader />;
    }

    const heartProps = {
      hasHearting,
      isCustomer,
      heartProduct,
      toggleHeartingLoginModal,
      trackEvent,
      unHeartProduct
    };
    const heartsData = getHeartProps(heartProps, {
      heartEventName: 'TE_LANDING_PRODUCT_HEART',
      unHeartEventName: 'TE_LANDING_PRODUCT_UNHEART'
    });

    const { slotData, pageLayout, fullWidth, fullBleed } = pageInfo;
    const isFullBleed = fullBleed === 'true' || isZAWPage(pageName);
    const isFullWidth = fullWidth === 'true' && !isFullBleed;
    const thisContainer = this;

    const seoProps = {};
    if (isFaqPage) {
      seoProps.itemScope = true;
      seoProps.itemType = 'https://schema.org/FAQPage';
    }

    return (
      <SiteAwareMetadata loading={!isLoaded}>
        <MartyContext.Consumer>
          {context => (
            <div
              {...seoProps}
              className={cn(css.pageWrap, { [css.fullWidth]: isFullWidth, [css.fullBleed]: isFullBleed })}
              data-test-id={context.testId('landingPage')}
              data-layout={pageLayout} // used for SiteMerch bookmarklets
              data-page-id={pageName}
            >
              {thisContainer.makePageHeading(isFullWidth)}
              {showAccountRewards && <VipPrimeLink isPageModal={true} />}
              {content?.fallback && <p className={css.hidden}>***Using Fallback Landing Page***</p>}
              {this.makeLayout(
                pageLayout,
                pageName,
                pageInfo,
                slotData,
                slotOrder,
                ipStatus,
                slotContentTypesList,
                heartsData,
                isFullWidth,
                isCustomer
              )}
            </div>
          )}
        </MartyContext.Consumer>
      </SiteAwareMetadata>
    );
  }
}

Landing.defaultProps = {
  trackEvent,
  trackLegacyEvent,
  track,
  setupLandingEventWatcher,
  onEvent
};

function mapStateToProps(state, ownProps) {
  return {
    landingPage: state.landingPage,
    ipStatus: state.landingPage.ipStatus,
    isHomepage: checkIsHomepage(ownProps.location.pathname),
    isCustomer: selectIsCustomer(state),
    params: ownProps.match?.params || ownProps.params,
    pageInfo: selectLandingPageInfo(state)
  };
}

function withHooks(Component) {
  const ComponentWithHooks = props => {
    const { isEnrolled: isLandingPageKratos } = useIsEnrolledInVariant(FEATURE_LANDING_PAGE_KRATOS);

    return <Component {...props} isLandingPageKratos={isLandingPageKratos} />;
  };
  return ComponentWithHooks;
}
const LandingWithHooks = withHooks(Landing);
// copies over static members like display name, fetchDataOnServer, webpackChunkName etc
hoistNonReactStatics(LandingWithHooks, Landing);

export default connect(mapStateToProps, {
  loadLandingPage,
  getIpRestrictedStatus,
  fetchAllRecommenderDataIfNecessary,
  firePixelServer,
  pageTypeChange,
  pushMicrosoftUetEvent,
  getHearts,
  heartProduct,
  unHeartProduct,
  toggleEasyFlowModal,
  toggleHeartingLoginModal,
  updateOriginalTerm,
  setHFSearchTerm,
  setFederatedLoginModalVisibility
})(withRouter(LandingWithHooks));
